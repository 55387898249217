var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "modal",
      attrs: {
        size: "lg",
        id:
          _vm.index != null
            ? "worker-assignments-modal-" + _vm.index
            : "worker-assignments-modal",
        "hide-footer": "",
        "hide-header": "",
      },
      on: {
        shown: function ($event) {
          return _vm.getWorkerAssignments()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "avo-header-text mb-4 bold",
          staticStyle: { "text-align": "center" },
        },
        [_vm._v(" Update assignments for " + _vm._s(_vm.worker.name) + ": ")]
      ),
      _c(
        "b-form",
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Practices:" } },
                    [
                      _c("multiselect", {
                        attrs: {
                          "show-labels": false,
                          options: _vm.practicesOptions,
                          "close-on-select": false,
                          "hide-selected": true,
                          "track-by": "text",
                          label: "text",
                          disabled: _vm.optionsLoading,
                          placeholder: "-- Select Practices --",
                          multiple: true,
                          required: "",
                        },
                        model: {
                          value: _vm.form.practices,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "practices", $$v)
                          },
                          expression: "form.practices",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Payers:" } },
                    [
                      _c("multiselect", {
                        attrs: {
                          "show-labels": false,
                          options: _vm.payersOptions,
                          "close-on-select": false,
                          "hide-selected": true,
                          "track-by": "text",
                          label: "text",
                          disabled: _vm.optionsLoading,
                          placeholder: "-- Select Payers --",
                          multiple: true,
                          required: "",
                        },
                        model: {
                          value: _vm.form.payers,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "payers", $$v)
                          },
                          expression: "form.payers",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.payersGroupsOptions && _vm.payersGroupsOptions.length > 0
                ? _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Add by Payer Group:" } },
                        [
                          _c("multiselect", {
                            attrs: {
                              "show-labels": false,
                              value: _vm.selectedPayerGroups,
                              options: _vm.payersGroupsOptions,
                              "close-on-select": false,
                              taggable: true,
                              multiple: true,
                              "track-by": "value",
                              label: "text",
                              disabled: _vm.optionsLoading,
                              placeholder: "-- Select Payer Group --",
                            },
                            on: {
                              select: _vm.addPayersByGroup,
                              remove: _vm.popPayerGroup,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "CPT Codes:" } },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            openDirection: "bottom",
                            placeholder: "Search by code or description",
                            label: "code",
                            "custom-label": _vm.codeWithDescription,
                            "track-by": "code",
                            options: _vm.selectableCPTCodes,
                            multiple: true,
                            disabled: _vm.optionsLoading,
                            loading: _vm.cptLoading,
                            "close-on-select": false,
                            "internal-search": false,
                            "show-labels": false,
                            showNoOptions: true,
                          },
                          on: { "search-change": _vm.debounceCPTCodes },
                          model: {
                            value: _vm.form.codes,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "codes", $$v)
                            },
                            expression: "form.codes",
                          },
                        },
                        [
                          _c("template", { slot: "noResult" }, [
                            _vm._v(" No codes matched your search "),
                          ]),
                          _c("template", { slot: "noOptions" }, [
                            _vm._v(" Start typing a code or description... "),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "ml-2", attrs: { label: "Age Ranges:" } },
                [
                  _c("worker-age-range-assignments", {
                    ref: "ageRanges",
                    attrs: { dummy: "", "worker-id": _vm.worker.worker_id },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "right" } },
        [
          _c(
            "b-button-group",
            [
              _c(
                "b-button",
                {
                  staticClass: "avo-basic-btn ml-2",
                  on: {
                    click: function ($event) {
                      return _vm.hideModal()
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "avo-primary-btn ml-2",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.updateUserAssignments()
                    },
                  },
                },
                [_vm._v("Update Worker assignments")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
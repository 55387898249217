var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { variant: "transparent", blur: null, show: _vm.loading } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-form-checkbox-group", {
                staticClass: "checkbox-btn-group shadow mb-1",
                attrs: {
                  options: _vm.age_ranges_options,
                  buttons: "",
                  "button-variant": "outline-primary",
                  id: "search-ages",
                },
                on: {
                  change: function ($event) {
                    _vm.save_needed = true
                  },
                },
                model: {
                  value: _vm.selected_ages,
                  callback: function ($$v) {
                    _vm.selected_ages = $$v
                  },
                  expression: "selected_ages",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "avo-basic-btn mr-1 mb-1",
                  attrs: { variant: "info", size: "sm" },
                  on: {
                    click: function ($event) {
                      _vm.selected_ages = []
                      _vm.save_needed = true
                    },
                  },
                },
                [_vm._v(" Clear ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.save_needed,
                      expression: "save_needed",
                    },
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.modal-1",
                      modifiers: { "modal-1": true },
                    },
                  ],
                  staticClass: "avo-basic-btn mr-1 mb-1",
                  attrs: { variant: "info", size: "sm" },
                  on: { click: _vm.undo },
                },
                [_vm._v("Undo")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.save_needed && !_vm.dummy,
                      expression: "save_needed && !dummy",
                    },
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.modal-1",
                      modifiers: { "modal-1": true },
                    },
                  ],
                  staticClass: "avo-btn mr-1 mb-1",
                  attrs: { variant: "primary", size: "sm" },
                  on: { click: _vm.addData },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }